import { lazy } from "react";
import { app, fullscreen } from "~/helpers/routes";
import { paths } from "~/router/paths";

const Dashboard = lazy(() => import("~/pages/dashboard"));

const ProductDatabase = lazy(() => import("~/pages/product-database"));
const ProductDatabaseDetails = lazy(
  () => import("~/pages/product-database/ProductDetails")
);

const MyTrackings = lazy(() => import("~/pages/my-trackings"));
const MyTrackingStores = lazy(() => import("~/pages/my-trackings/TrackStores"));
const MyTrackingProducts = lazy(() => import("~/pages/my-trackings/Products"));
const StoreDetailPage = lazy(() => import("~/pages/store-detail"));
const ProductDetailPage = lazy(() => import("~/pages/product-detail"));
const TopProductsPage = lazy(() => import("~/pages/top-products"));
const TopStoresPage = lazy(() => import("~/pages/top-stores"));

const CompetitorPage = lazy(() => import("~/pages/competitor"));
export const routes: Array<any> = [
  {
    path: paths.app.home,
    element: app(Dashboard),
  },
  {
    path: paths.app.myTrackingStores,
    element: app(MyTrackingStores),
  },
  {
    path: paths.app.myTrackingStores,
    element: app(MyTrackingStores),
  },
  {
    path: paths.app.myTrackingProducts,
    element: app(MyTrackingProducts),
  },
  {
    // new route for a specific store
    path: paths.app.myTrackingStoreDetail,
    element: app(StoreDetailPage),
  },
  {
    path: paths.app.myTrackingProductDetail,
    element: app(ProductDetailPage),
  },
  {
    path: paths.app.topProducts,
    element: app(TopProductsPage),
  },
  {
    path: paths.app.topStores,
    element: app(TopStoresPage),
  },
  {
    path: paths.app.productDatabase,
    element: app(ProductDatabase),
  },
  // {
  //   path: paths.app.productDatabaseDetails,
  //   element: app(ProductDatabaseDetails),
  // },
  {
    path: paths.app.competitorResearchDetails,
    element: app(ProductDatabaseDetails),
  },
  {
    path: paths.app.competitorResearch,
    element: app(CompetitorPage),
  },
];
